<template>
  <div class="editNums">
    <el-dialog
      :title="subData.id ? '修改小科' : '添加小科'"
      :visible.sync="dialogVisible"
      width="486px"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item label="选择阅卷小科" prop="subjectId">
          <el-select v-model="ruleForm.subjectId" placeholder="请选择">
            <template v-for="item in subjuectArr">
              <el-option
                v-if="disSubjectArr.indexOf(item.subjectId) == -1"
                :key="item.subjectId"
                :label="item.subjectName"
                :value="item.subjectId"
              >
              </el-option
            ></template>
          </el-select>
        </el-form-item>
        <div class="score-tips">主观题({{ getAllScore() }})</div>
        <el-form-item label="" prop="sheetDetailIds">
          <el-checkbox-group v-model="ruleForm.sheetDetailIds">
            <el-checkbox
              v-for="(item, index) in questionList"
              :key="index"
              :label="item.sheetDetailId"
              name="type"
              :disabled="
                disArr.indexOf(item.sheetDetailId) != -1 || questionDis(item)
              "
            >
              <!-- {{ item.isOption == 0 ? "主观题" : "选做题" }} -->
              {{ item.questionNum }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitForm()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  sectionSubject,
  exammarkSectionSave,
} from "@/core/api/exam/examExammarkSection";
export default {
  name: "EditNums",
  components: {},
  data() {
    var checkNums = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择科目"));
      }
      callback();
    };
    var checksheetDetailIds = (rule, value, callback) => {
      if (!value || value.length == 0) {
        return callback(new Error("请选择试题"));
      }
      callback();
    };
    return {
      dialogVisible: false,
      btnLoading: false,
      type: false,
      ruleForm: {
        subjectId: "",
        sheetDetailIds: [],
      },
      disSubjectArr: [],
      disArr: [],
      subjuectArr: [],
      questionList: [],
      subData: {},
      rules: {
        subjectId: [{ validator: checkNums, trigger: "blur" }],
        sheetDetailIds: [{ validator: checksheetDetailIds, trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    questionDis(item) {
      let type = false;
      if (
        item.bindQuestionNum &&
        item.bindQuestionNum.split(",").indexOf(item.questionNum) != 0
      ) {
        type = true;
      }
      return type;
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.lintQusetion();
        } else {
          return false;
        }
      });
    },
    getAllScore() {
      let score = 0;
      this.questionList.map((item) => {
        score = score + Number(item.questionScore);
      });
      return score + "分";
    },
    lintQusetion() {
      let sheetDetailIds = this.ruleForm.sheetDetailIds;

      let arr = this.questionList.filter(
        (item) => sheetDetailIds.indexOf(item.sheetDetailId) != -1
      );

      let isTeacher = false;
      let questionArr = [];
      arr.map((item) => {
        if (item.isTeacher) {
          isTeacher = true;
          questionArr.push(item.questionNum);
        }
      });
      if (isTeacher) {
        let str = `当前${questionArr.join(
          ","
        )}小题已设置学科阅卷教师，若需设置为阅卷小科请情况阅卷分配后继续`;
        if (this.subData.id) {
          str =
            "当前有小题已设置学科阅卷教师，修改设置，将会影响阅卷教师分工请清空当前小题的阅卷教师后，再行设置";
        }
        this.$confirm(str, "温馨提示", {
          confirmButtonText: "返回阅卷分配",
          cancelButtonText: "取消",
          type: "warning",
          confirmButtonClass: "el-button--danger",
        })
          .then(() => {
            this.$router.push({
              name: "unionExamCorrectTask",
              query: this.$route.query,
            });
          })
          .catch(() => {});
      } else {
        this.setupdatetempevaluatenum();
      }
    },
    setupdatetempevaluatenum() {
      let data = Object.assign({}, this.ruleForm);

      this.btnLoading = true;
      let arr = [];
      this.questionList.map((item) => {
        if (data.sheetDetailIds.indexOf(item.sheetDetailId) != -1) {
          if (item.bindQuestionNum) {
            arr.push(item.sheetDetailId);
            this.questionList.map((index) => {
              if (item.bindQuestionNum.indexOf(index.questionNum) > 0) {
                arr.push(index.sheetDetailId);
              }
            });
          } else {
            arr.push(item.sheetDetailId);
          }
        }
      });

      data.sheetDetailIds = arr.join(",");

      data.examPaperId = this.$route.query.paperId;
      if (this.subData.id) {
        data.id = this.subData.id;
      }
      exammarkSectionSave(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功",
            type: "success",
          });
          this.$parent.getList();
          this.dialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    getSubjectList() {
      sectionSubject({
        examId: this.$route.query.examId,
      }).then((res) => {
        this.subjuectArr = res.data.data;
      });
    },
    init(data) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        if (data) {
          this.ruleForm.subjectId = data.subjectId;
          this.ruleForm.sheetDetailIds = data.sheetDetailId
            .split(",")
            .map(Number);
          this.subData = data;
        } else {
          this.subData = {};
        }

        this.disArr = [];
        this.disSubjectArr = [];
        this.$parent.sectionList.map((item) => {
          if (item.id != this.subData.id) {
            let questionid = item.sheetDetailId.split(",").map(Number);
            this.disArr = this.disArr.concat(questionid);
            this.disSubjectArr.push(item.subjectId);
          }
        });
      });

      this.questionList = this.$parent.questionList;

      this.getSubjectList();
    },
  },
};
</script>
<style scoped lang="scss">
.editNums {
  .score-tips {
    color: #2474ed;
    margin-bottom: 18px;
  }
  .el-form-item {
    margin-bottom: 18px;
  }
  ::v-deep {
    .el-form-item__content {
      line-height: 32px;
    }
    .el-form-item__label {
      line-height: 32px;
    }
  }
  .el-checkbox {
    min-width: 33%;
    margin-right: 0;
    margin-bottom: 0;
  }
  .form-item {
    display: flex;

    .el-input {
      margin-right: 8px;
    }
    > span {
      flex-shrink: 0;
    }
  }
}
</style>
