<template>
  <div class="ExamMarkingSmallSubject">
    <ExamDetailsHeader router-url="unionExamCorrectTask" :query="$route.query">
    </ExamDetailsHeader>
    <div class="smallSubject-box">
      <div>
        <el-button type="primary" @click="addFrom()">
          <img src="@/assets/uExam/icon_add_w.png" alt="" />
          <img class="on" src="@/assets/uExam/icon_add_w.png" alt="" />
          添加小科
        </el-button>
      </div>
      <div class="err-tips">
        <img src="@/assets/uExam/icon_waring.png" alt="" />
        注意事项：若试卷结构被调整(拆分、合并等)，则需重新设置阅卷小科！
      </div>

      <el-table :data="sectionList" border style="width: 100%">
        <el-table-column
          prop="subjectName"
          label="科目名称"
          width="220"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="questionNum" label="题目名称" align="center">
        </el-table-column>
        <el-table-column
          prop="bindQuestionNum"
          label="操作"
          align="center"
          width="220"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="addFrom(row)">修改</el-button>
            <el-button type="text" style="color: #f56c6b" @click="delItem(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <editForm ref="editForm"></editForm>
  </div>
</template>
<script>
import ExamDetailsHeader from "@/components/detailsHeader";
import editForm from "./components/editForm";
import { getObj } from "@/core/api/exam/exam";
import {
  exammarkList,
  sectiondelete,
} from "@/core/api/exam/examExammarkSection";
import { exammarkTeacherList } from "@/core/api/exam/examCorrectTask";

export default {
  name: "ExamMarkingSmallSubject",
  components: {
    ExamDetailsHeader,
    editForm,
  },
  data() {
    return {
      examInformation: {},
      sectionList: [],
      questionList: [],
    };
  },
  created() {
    this.getObj();
    this.getList();
    this.schoolExamwork();
  },
  methods: {
    schoolExamwork() {
      let data = {
        examPaperId: this.$route.query.paperId,
      };

      exammarkTeacherList(data)
        .then((res) => {
          this.questionList = res.data.data;
        })
        .catch(() => {});
    },
    addFrom(data) {
      this.$refs.editForm.init(data);
    },
    delItem(data) {
      let str = `当前正在删除 ${data.subjectName} 阅卷小科,删除后不可恢复, 是否继续?`;
      if (data.isTeacher == 1) {
        str =
          "当前小题已设置学科阅卷教师，若需重新设置阅卷科目，请清空当前小题的阅卷教师后，再行设置！";
      }
      this.$confirm(str, "提示", {
        confirmButtonText: data.isTeacher == 1 ? "继续删除" : "继续",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "el-button--danger",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            sectiondelete({
              examPaperId: this.$route.query.paperId,
              subjectId: data.subjectId,
            })
              .then(() => {
                this.$message({
                  showClose: true,
                  message: "删除成功",
                  type: "success",
                });
                done();
                instance.confirmButtonLoading = false;
                this.getList();
              })
              .catch(() => {
                instance.confirmButtonLoading = false;
              });

            // setTimeout(() => {
            //   done();
            //   setTimeout(() => {
            //     instance.confirmButtonLoading = false;
            //   }, 300);
            // }, 3000);
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    //获取考试基本信息
    getObj() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        this.name = res.data.data.name;
      });
    },
    getList() {
      exammarkList({
        examPaperId: this.$route.query.paperId,
      }).then((res) => {
        this.sectionList = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss">
.del-btn {
  background-color: #f57574;
  border-color: #f57574;
}
</style>
<style lang="scss" scoped>
.ExamMarkingSmallSubject {
  margin-top: 18px;

  .smallSubject-box {
    background: #ffffff;
    padding: 18px;
    margin-top: 18px;
  }
  .err-tips {
    background: #fff3f3;
    border-radius: 4px;
    padding: 12px 18px;
    color: #292626;
    margin-top: 18px;
  }
  .el-table {
    margin-top: 18px;
  }
}
</style>
