import request from "@/core/services/axios";

// 联考-查询阅卷小科列表
export function exammarkList(query) {
  return request({
    url: "/exam/exammark/section/list",
    method: "get",
    params: query,
  });
}
// 平台已开通科目但本次考试中未勾选的
export function sectionSubject(query) {
  return request({
    url: "/exam/exammark/section/subject",
    method: "get",
    params: query,
  });
}
// 删除阅卷小科
export function sectiondelete(query) {
  return request({
    url: "/exam/exammark/section/delete",
    method: "get",
    params: query,
  });
}
// 保存阅卷小科
export function exammarkSectionSave(query) {
  return request({
    url: "/exam/exammark/section/save",
    method: "post",
    data: query,
  });
}
